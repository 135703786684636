import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { HomePage } from "./home";
import { Header } from "./navigation";
import { InstallPage } from "./windows/install";
import { PrerequisitesPage } from "./pre-requisites";
import { ConnectionPage } from "./windows/connecting";
import { UpdatingPage } from "./windows/updating";
import { TroubleshootingPage } from "./windows/troubleshooting";
import { Scroll } from "../common/utils";

const App = () => {
  return (
    <Router>
      <Header />
      <Scroll />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/vpn-connection-prerequisites.html"
          element={<PrerequisitesPage />}
        />
        <Route path="/windows/install.html" element={<InstallPage />} />
        <Route path="/windows/vpn-connect.html" element={<ConnectionPage />} />
        <Route path="/windows/updating.html" element={<UpdatingPage />} />
        <Route
          path="/windows/troubleshooting.html"
          element={<TroubleshootingPage />}
        />
      </Routes>
    </Router>
  );
};

export default App;
