import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ACCOUNT_MAPPINGS } from "./constants";
import MockAdapter from "axios-mock-adapter";
import axios, { AxiosRequestConfig } from "axios";

export const axiosInstance = axios.create();

/* istanbul ignore next */
export const copyClipboard = () => {
  // basic script to copy code into user clipboard
  const codeBlock = document.querySelector(".code-block");
  navigator.clipboard.writeText(codeBlock?.textContent ?? "");
};

/* istanbul ignore next */
export function Scroll() {
  // Scroll to top of page or specific div after clicking on Link
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash !== "") {
      const e = document.getElementById(hash.substring(1));
      e?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
}

export const getPostureResult = async (
  postureData: string,
  userId: string | undefined
) => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      user_posture_info: postureData,
      user_id: userId ?? "posturetest-user",
    },
  };

  const hostname = window?.location.hostname;
  const apiDomain = ACCOUNT_MAPPINGS.get(hostname);

  return await axiosInstance.get(`https://${apiDomain}`, reqConfig);
};

export const mockApi = () => {
  var mockAdapter = new MockAdapter(axiosInstance, { delayResponse: 500 });

  // if you want to edit the posture response do it here manually - this
  // is for quick feedback in the mock server
  mockAdapter.onGet().reply(() => [200, { result: true, reason: {} }]);
  return mockAdapter;
};
