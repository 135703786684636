import { Alert } from "@amzn/awsui-components-react";
import { Link } from "react-router-dom";

export const PrerequisitesPage = () => {
  return (
    <body>
      <h1>Connection Prerequisites</h1>
      <Alert type="warning">
        If you are an <b>Amazon employee</b> use the internal policies and wikis
        to assist vendor setup.
      </Alert>
      <br />
      <p>
        To connect to a VPN, you must have the following:
        <ul>
          <li>
            For third party vendors:
            <ul>
              <li>
                You must have an email address registered with IdPrism. Work
                with your Amazon point of contact to set this up.
              </li>
              <li>
                Supported{" "}
                <Link to="/windows/troubleshooting.html#supported-os">
                  OS version
                </Link>
              </li>
              <li>Enabled Firewall</li>
              <li>Enabled Antivirus</li>
              <li>AWS VPN Client</li>
              <li>
                SiteConnect Web Server{" "}
                <Link to="/windows/install.html">Installed</Link>
              </li>
            </ul>
          </li>
          <li>VPN Endpoint URL</li>
          <li>OVPN Configuration File</li>
        </ul>
      </p>

      <h2>IdPrism Account</h2>
      <p>
        SiteConnect uses IdPrism as a central identity store for third-party
        vendors. All non-Amazon users must be registered in IdPrism before using
        SiteConnect. Your Amazon point of contact (POC) can onboard you into
        IdPrism.
        <br />
        <br />
        You will need an email address and phone number that can receive SMS. To
        check if you have an account already or to reset your password, you can
        log in and verify your account here:{" "}
        <Link
          to="https://opstechitgate.cupid-na.idprism-auth.amazon.dev/"
          target="_blank"
        >
          https://opstechitgate.cupid-na.idprism-auth.amazon.dev/
        </Link>
      </p>

      <h2>VPN Endpoint URL</h2>
      <p>
        Once you have been granted access by your Amazon point of contact, they
        will provide you with a URL. This URL is where you will authenticate and
        download the OVPN configuration file (see below).
        <br />
        <br />
        You will receive this URL <b>after</b> access has been granted by the
        Amazon POC, and you will get a new URL for every access request. For the
        older vendors who are used to the token system, instead of providing you
        a token, you are now provided a URL.
      </p>

      <h2>OVPN Configuration File</h2>
      <p>
        The OVPN configuration file has a <code>.ovpn</code>
        file extension and is also referred to as the <b>profile</b> when using
        the AWS VPN Client.
        <br />
        <br />
        You will need a separate OVPN file for <b>each</b> site you attempt to
        connect to.
        <br />
        <br />
        This file becomes available after you authenticate at the provided URL
        (from above). You will need to download a new OVPN file for every access
        request.
      </p>
    </body>
  );
};
