import { Link } from "react-router-dom";
import "../../css/navbar.css";

export const Header = () => {
  return (
    <div className="topnav">
      <Link to="/">Home</Link>
      <Link to="/vpn-connection-prerequisites.html">Pre-Requisites</Link>
      <Link to="/windows/install.html">Install</Link>
      <Link to="/windows/vpn-connect.html">Connecting</Link>
      <Link to="/windows/updating.html">Updating</Link>
      <Link to="/windows/troubleshooting.html">Troubleshooting</Link>
    </div>
  );
};
